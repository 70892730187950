import "../scss/style.scss";

import { buttonActionFunc } from "./common/_buttonAction";
import { headerFunc } from "./common/_header";
import { footerFunc } from "./common/_footer";
import { commonFunc } from "./common/_common";
import { homeFunc } from "./pages/_home";

$(window).on("load", function () {
  commonFunc();
  headerFunc();
  footerFunc();
  buttonActionFunc();
  homeFunc();
});
